@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaijaan|Ubuntu');
.header-container {
    display: flex;
}

.GqMIW {
    /* max-width: 1002px; */
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 26px 0px;
}

.gQRraV {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: rgb(74, 74, 74);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* max-width: 800px; */
}


/* .title-grp {
    flex: 1;
    justify-content: start;
    border: 1px solid red;
}

.flag-grp {
    flex: 1;
    justify-content: flex-end;
    border: 1px solid red;
} */

.flag-grp {
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.main-container {
    display: flex;
}

.company-card {
    flex: 2;
    box-shadow: 0 0px 0px 0px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin-left: 5%;
    height: 60vh;
    margin-top: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.company-info-container {
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
}

.company-info-container h3 {
    font-weight: 300;
}

.company-info-container .desc {
    font-size: 13px;
    font-weight: 100;
}

.company-info-container span {
    font-size: 16px;
}

.company-info-container b {
    font-size: 24px;
}

.wrapper {
    flex: 3;
}

.logo-image {
    width: 310px;
    height: 200px;
    object-fit: contain;
    /* margin-top: -50px; */
}

.payment {
    width: 70%;
    background: #FFFFFF;
    /* max-width: 360px; */
    margin: 80px auto;
    height: auto;
    padding: 35px;
    padding-top: 70px;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0px 0px 0px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    /* border: 1px solid red; */
}

.css-160yrbf-SaveCardPermission {
    margin-bottom: 16px !important;
    background-color: rgb(248, 249, 250) !important;
    border-radius: 4px !important;
    padding: 16px !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
}

.payment-title {
    opacity: 0.9;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(33, 37, 41);
    margin-left: 12px;
}

.form .label {
    display: block;
    color: #555555;
    margin-bottom: 6px;
}

.input {
    padding: 13px 0px 13px 25px;
    width: 100%;
    text-align: center;
    border: 2px solid #dddddd;
    border-radius: 5px;
    letter-spacing: 1px;
    word-spacing: 3px;
    outline: none;
    font-size: 16px;
    color: #555555;
}

.card-grp {
    display: flex;
    justify-content: space-between;
}

.card-item {
    width: 48%;
}

.space {
    margin-bottom: 20px;
}

.icon-relative {
    position: relative;
    border: none;
}

.icon-relative .fas,
.icon-relative .far {
    position: absolute;
    bottom: 12px;
    left: 15px;
    font-size: 20px;
    color: #555555;
}

.btncustom {
    /* margin-top: 40px;
    background: #2196F3;
    padding: 12px;
    text-align: center;
    color: #f8f8f8;
    border-radius: 5px;
    cursor: pointer; */
    background-position: center center;
    user-select: none !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    cursor: pointer !important;
    width: 100% !important;
    height: 48px !important;
    line-height: 48px !important;
    border-radius: 100px !important;
    opacity: 1 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    outline: none !important;
    padding: 0px !important;
    background-color: rgb(30, 100, 255) !important;
    color: rgb(255, 255, 255) !important;
    border: none !important;
    transition: all 0.3s linear 0s !important;
}

.css-160yrbf-SaveCardPermission {
    margin-bottom: 16px !important;
    background-color: rgb(248, 249, 250) !important;
    border-radius: 4px !important;
    padding: 16px !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
}

.css-1fq3mss-Wrapper label {
    display: flex !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    position: relative !important;
    font-weight: normal !important;
}

.css-1msrf2r-Global strong {
    font-weight: bold !important;
}

.css-6eiscf-SaveCardInfo {
    margin-top: 8px !important;
    margin-left: 23px !important;
    text-align: left !important;
    font-size: 12px !important;
}

.payment-logo {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    /* background: #f8f8f8; */
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 85px;
    z-index: 1;
}

.payment-logo:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 90px;
    height: 90px;
    background: #fff;
    border-radius: 50%;
}

.payment-logo p {
    position: relative;
    color: #f8f8f8;
    font-family: 'Baloo Bhaijaan', cursive;
    font-size: 58px;
    margin-top: 10px;
}

.fZQoNm {
    max-width: 1002px;
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 16px;
}

.equHBw {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.equHBw svg {
    margin-left: 20px;
}

.form_error {
    color: red;
}

@media screen and (max-width: 720px) {
    .main-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .payment {
        width: 90%;
    }
    .company-card {
        margin-top: 0px;
        margin-left: 0px;
        width: 90%;
    }
    .logo-image {
        margin-top: 20px;
        width: 100px;
        height: 100px;
    }
    .company-info-container {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 420px) {
    .card-grp {
        flex-direction: column;
    }
    .card-item {
        width: 100%;
        margin-bottom: 20px;
    }
    .btncustom {
        margin-top: 20px;
    }
    .equHBw svg {
        margin-left: 10px;
    }
}